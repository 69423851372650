import { drawer } from './types';

const initialState = {
  levels: {
    data: [
      {
        value: 'spm',
        label: 'SPM',
      },
      {
        value: 'stpm',
        label: 'STPM',
      },
    ],
  },
  subjects: {
    data: [
      {
        label: 'Bahasa Melayu',
        value: 'bm',
      },
      {
        label: (t?: any) => t('filter.bi'),
        value: 'bi',
      },
      {
        label: (t?: any) => t('filter.science'),
        value: 'science',
      },
      {
        label: (t?: any) => t('filter.math'),
        value: 'math',
      },
    ],
  },
  upload_date: {
    data: [
      {
        label: (t?: any) => t('filter.last_hour'),
        value: 'lhr',
      },
      {
        label: (t?: any) => t('filter.today'),
        value: 'tdy',
      },
      {
        label: (t?: any) => t('filter.this_week'),
        value: 'twk',
      },
      {
        label: (t?: any) => t('filter.this_month'),
        value: 'tmh',
      },
      {
        label: (t?: any) => t('filter.this_year'),
        value: 'tyr',
      },
    ],
  },
  durations: {
    data: [
      {
        label: (t?: any) => t('filter.short'),
        value: 'shr',
      },
      {
        label: (t?: any) => t('filter.long'),
        value: 'log',
      },
    ],
  },
  watching_progress: {
    data: [
      {
        label: (t?: any) => t('filter.not_started'),
        value: 'not_started',
      },
      {
        label: (t?: any) => t('filter.in_progress'),
        value: 'in_progress',
      },
      {
        label: (t?: any) => t('filter.completed'),
        value: 'completed',
      },
    ],
  },
  sort_by: {
    data: [
      {
        label: (t?: any) => t('filter.title_a_to_z'),
        value: 'asc',
      },
      {
        label: (t?: any) => t('filter.title_z_to_a'),
        value: 'desc',
      },
      // {
      //     label: (t?: any) => t('filter.relevance'),
      //     value: 'rlc',
      // },
      // {
      //     label: (t?: any) => t('filter.upload_date'),
      //     value: 'upd',
      // },
      // {
      //     label: (t?: any) => t('filter.view_count'),
      //     value: 'vct',
      // },
      // {
      //     label: (t?: any) => t('filter.rating'),
      //     value: 'rtg',
      // },
    ],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default todos;
