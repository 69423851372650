import React from 'react';
import { config } from '../../../../app.config';
import Axios from 'axios';
import {
  authHeaderAppJson,
  headerAppJson,
} from '../../../../SERVICES/axios-header';
import queryString from 'query-string';
import { getErrorMessageFromAxios } from '../../../../SERVICES/user-service';

// export const getVideoList = (action: any) => {
//     return Axios.get(`${config.SERVER_ENDPOINT}/video/list`, {
//         ...authHeaderAppJson(),
//         params: { ...action.params },
//     })
//         .then((resp) => resp.data)
//         .catch((err) => {
//             getErrorMessageFromAxios(err);
//             throw err;
//         });
// };

export const getStudentCertList = (action: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`${config.SERVER_ENDPOINT}/my-ecert`, {
      ...authHeaderAppJson(),
    })
      .then((resp) => resolve(resp.data))
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};

export const getMyProgress = (action: any) => {
  return new Promise((resolve, reject) => {
    Axios.get(`${config.SERVER_ENDPOINT}/my-progress`, {
      ...authHeaderAppJson(),
    })
      .then((resp) => resolve(resp.data))
      .catch((err) => {
        getErrorMessageFromAxios(err);
        throw err;
      });
  });
};
