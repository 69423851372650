export enum type {
    GET_WATCHING_PROGRESS_LIST = 'GET_WATCHING_PROGRESS_LIST',
    GET_WATCHING_PROGRESS_LIST_ASYNC = 'GET_WATCHING_PROGRESS_LIST_ASYNC',
    GET_RECOMMENDED_VIDEO_LIST = 'GET_RECOMMENDED_VIDEO_LIST',
    GET_RECOMMENDED_VIDEO_LIST_ASYNC = 'GET_RECOMMENDED_VIDEO_LIST_ASYNC',
    GET_MY_LIKED_VIDEO_LIST = 'GET_MY_LIKED_VIDEO_LIST',
    GET_MY_LIKED_VIDEO_LIST_ASYNC = 'GET_MY_LIKED_VIDEO_LIST_ASYNC',
    GET_VIDEOS_BY_TOTAL_LIKE = 'GET_VIDEOS_BY_TOTAL_LIKE',
    GET_VIDEOS_BY_TOTAL_LIKE_ASYNC = 'GET_VIDEOS_BY_TOTAL_LIKE_ASYNC',
    GET_VIDEOS_BY_TOTAL_COMMENT = 'GET_VIDEOS_BY_TOTAL_COMMENT',
    GET_VIDEOS_BY_TOTAL_COMMENT_ASYNC = 'GET_VIDEOS_BY_TOTAL_COMMENT_ASYNC',
    GET_VIDEOS_BY_TOTAL_VIEW = 'GET_VIDEOS_BY_TOTAL_VIEW',
    GET_VIDEOS_BY_TOTAL_VIEW_ASYNC = 'GET_VIDEOS_BY_TOTAL_VIEW_ASYNC',
}
