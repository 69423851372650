import { call, takeLatest, put, delay } from 'redux-saga/effects';
import { type } from './type';
import { getVideoList } from './axios';

function* watch_getVideoList(action: any) {
    try {
        yield put({
            type: type.GET_WATCHING_PROGRESS_LIST_ASYNC,
            is_loading: true,
        });
        const data = yield call(getVideoList, action);
        yield put({
            type: type.GET_WATCHING_PROGRESS_LIST_ASYNC,
            data: data.data,
            status: data.status,
            is_loading: false,
            total_records: data.total_records,
            total_retrieved: data.total_retrieved,
        });
    } catch (error) {
        throw error;
    }
}

function* watch_getRecommendedVideoList(action: any) {
    try {
        yield put({
            type: type.GET_RECOMMENDED_VIDEO_LIST_ASYNC,
            is_loading: true,
        });
        const data = yield call(getVideoList, action);
        yield put({
            type: type.GET_RECOMMENDED_VIDEO_LIST_ASYNC,
            data: data.data,
            status: data.status,
            is_loading: false,
            total_records: data.total_records,
            total_retrieved: data.total_retrieved,
        });
    } catch (error) {
        throw error;
    }
}

function* watch_getVideoListByTotalLike(action: any) {
    try {
        yield put({
            type: type.GET_VIDEOS_BY_TOTAL_LIKE_ASYNC,
            is_loading: true,
        });
        const data = yield call(getVideoList, action);
        yield put({
            type: type.GET_VIDEOS_BY_TOTAL_LIKE_ASYNC,
            data: data.data,
            status: data.status,
            is_loading: false,
            total_records: data.total_records,
            total_retrieved: data.total_retrieved,
        });
    } catch (error) {
        throw error;
    }
}
function* watch_getVideoListByTotalComment(action: any) {
    try {
        yield put({
            type: type.GET_VIDEOS_BY_TOTAL_COMMENT_ASYNC,
            is_loading: true,
        });
        const data = yield call(getVideoList, action);
        yield put({
            type: type.GET_VIDEOS_BY_TOTAL_COMMENT_ASYNC,
            data: data.data,
            status: data.status,
            is_loading: false,
            total_records: data.total_records,
            total_retrieved: data.total_retrieved,
        });
    } catch (error) {
        throw error;
    }
}
function* watch_getVideoListByTotalView(action: any) {
    try {
        yield put({
            type: type.GET_VIDEOS_BY_TOTAL_VIEW_ASYNC,
            is_loading: true,
        });
        const data = yield call(getVideoList, action);
        yield put({
            type: type.GET_VIDEOS_BY_TOTAL_VIEW_ASYNC,
            data: data.data,
            status: data.status,
            is_loading: false,
            total_records: data.total_records,
            total_retrieved: data.total_retrieved,
        });
    } catch (error) {
        throw error;
    }
}

function* watch_getMyLikedVideoList(action: any) {
    try {
        yield put({
            type: type.GET_MY_LIKED_VIDEO_LIST_ASYNC,
            is_loading: true,
        });
        const data = yield call(getVideoList, action);
        yield put({
            type: type.GET_MY_LIKED_VIDEO_LIST_ASYNC,
            data: data.data,
            status: data.status,
            is_loading: false,
            total_records: data.total_records,
            total_retrieved: data.total_retrieved,
        });
    } catch (error) {
        throw error;
    }
}

export function* watchVideosSagas() {
    yield takeLatest(type.GET_WATCHING_PROGRESS_LIST, watch_getVideoList);
    yield takeLatest(
        type.GET_RECOMMENDED_VIDEO_LIST,
        watch_getRecommendedVideoList
    );
    yield takeLatest(
        type.GET_VIDEOS_BY_TOTAL_LIKE,
        watch_getVideoListByTotalLike
    );
    yield takeLatest(
        type.GET_VIDEOS_BY_TOTAL_COMMENT,
        watch_getVideoListByTotalComment
    );
    yield takeLatest(
        type.GET_VIDEOS_BY_TOTAL_VIEW,
        watch_getVideoListByTotalView
    );
    yield takeLatest(type.GET_MY_LIKED_VIDEO_LIST, watch_getMyLikedVideoList);
}
