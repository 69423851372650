import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLanguage,
  getTheme,
  getUserSession,
} from '../../SERVICES/user-service';
import { app, user } from '../../STORES/types';
import WithWidthRendering from './with-width-rendering';
// import DeviceDetect from './device-detect';
import 'lazysizes';
import { useLocation } from 'react-router-dom';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
const user_session: any = getUserSession();
const InitialRendering = (props: any) => {
  const dispatch = useDispatch();
  const redux = useSelector((state: any) => state.redux);
  React.useEffect(() => {
    const theme: any = getTheme();
    const language: any = getLanguage();

    dispatch({
      type: app.CHANGE_THEME,
      theme: theme,
    });
    dispatch({
      type: app.CHANGE_LANGUAGE,
      language: language,
    });

    if (user_session?.token) {
      dispatch({
        type: user.USER_INIT,
      });
      dispatch({
        type: user.USER_LOGIN_STATUS,
        is_login: true,
      });
    } else {
      dispatch({
        type: user.USER_INIT_ASYNC,
        data: {
          is_loading: false,
          id: 1,
          avatar: null,
          email: `${language == 'my' ? 'pelawat' : 'guest'}@gmail.com`,
          first_name: `${language == 'my' ? 'Pelawat' : 'Guest'}`,

          last_name: `${language == 'my' ? 'Pelawat' : 'Guest'}`,
          bio: null,
          role: null,
        },
      });
    }
  }, []);

  return <div></div>;
};

const MainLayout = (props: any) => {
  const redux = useSelector((state: any) => state.redux);
  const location = useLocation();
  useEffect(() => {
    document.body.classList.add(
      'app-view--wrapper',
      redux.app.current_grid,
      redux.app.default_theme
    );
    return () => {
      document.body.classList.remove(
        'app-view--wrapper',
        redux.app.current_grid,
        redux.app.default_theme
      );
    };
  }, [redux.app.default_theme, redux.app.current_grid]);
  return (
    <div
    //className={`app-view--wrapper ${redux.app.current_grid} ${redux.app.default_theme}`}
    >
      {/* <DeviceDetect /> */}
      <WithWidthRendering />
      <InitialRendering />

      {/* <Helmet>
                <title>{redux.app.title}</title>
                <link
                    rel='canonical'
                    href={`${config.CLIENT_ENDPOINT}${location.pathname}`}
                />
                <meta charSet='utf-8' />
                <meta name='description' content={redux.app.description} />
            </Helmet> */}

      {props.children}
    </div>
  );
};

export default MainLayout;
