import { call, takeLatest, put, delay } from 'redux-saga/effects';
import { type } from './type';
import { getStudentCertList, getMyProgress } from './axios';

function* watch_getStudentCertList(action: any) {
  try {
    yield put({
      type: type.GET_STUDENT_CERT_LIST_ASYNC,
      is_loading: true,
    });
    const data = yield call(getStudentCertList, action);
    yield put({
      type: type.GET_STUDENT_CERT_LIST_ASYNC,
      data: data.data,
      status: data.status,
      is_loading: false,
      total_records: data.total_records,
      total_retrieved: data.total_retrieved,
    });
  } catch (error) {
    throw error;
  }
}

function* watch_getMyProgress(action: any) {
  try {
    yield put({
      type: type.GET_DASHBOARD_MY_PROGRESS_ASYNC,
      is_loading: true,
    });
    const data = yield call(getMyProgress, action);
    yield put({
      type: type.GET_DASHBOARD_MY_PROGRESS_ASYNC,
      data: data.data,
      status: data.status,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* watchStudentCertSagas() {
  yield takeLatest(type.GET_STUDENT_CERT_LIST, watch_getStudentCertList);
  yield takeLatest(type.GET_DASHBOARD_MY_PROGRESS, watch_getMyProgress);
}
