import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History, createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import eBook from '../PAGES/ebook/store/reducer';
import watchingProgress from '../PAGES/learning-progress/store/reducer';
import userViewOnly from '../PAGES/user-view-only/store/reducer';
import studentCert from '../PAGES/user/certificate/store/reducer';
import videosTeacher from '../PAGES/user/videos/store/reducer';
import videos from '../PAGES/video-list/store/reducer';
import app from './app-reducer';
import drawer from './drawer-reducer';
import user from './user-reducer';

import general_data from './general-data-reducer';

import rootSaga from './root-sagas';

const createRootReducer = (history: History) =>
  combineReducers({
    redux: combineReducers({
      app,
      drawer,
      user,
      user_view_only: userViewOnly,
      data: general_data,
      module: combineReducers({
        video: videos,
        video_teacher: videosTeacher,
        watching_progress: watchingProgress,
        ebook: eBook,
        student_cert: studentCert,
      }),
    }),
    router: connectRouter(history),
  });

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

export default function configureStore(history: History) {
  //Init middlewares
  const middlewares = [routerMiddleware(history), sagaMiddleware];

  //Init enhancer
  const enhancer = compose(applyMiddleware(...middlewares));

  //Store creation
  const store = createStore(createRootReducer(history), enhancer);
  sagaMiddleware.run(rootSaga);
  return store;
}
