import { type } from './type';

const initialState: any = {
    list: {
        is_loading: true,
        total_records: 0,
        total_retrieved: 0,
        data: [
            {
                id: 1,
                title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',

                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/QHBpSxC2Iv0/mqdefault_6s.webp?du=3000&sqp=CMPCw_4F&rs=AOn4CLDOkXEFDV0RNKDJ4JRvbL1-9m8Ndw',
                },
                desc:
                    'Mohon teman-teman bantu subscribe channel kami agar lebih semangat untuk buat video baru. belajar ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 2,
                title: 'Mari Belajar Suku Kata - 3D Animasi (DVD Version)',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/ikRNErwR0pc/mqdefault_6s.webp?du=3000&sqp=CNykw_4F&rs=AOn4CLBTZeKFWa-Qqj_0mdERjS2HyFSIBg',
                },
                desc:
                    'Mari Belajar Suku Kata - 3D Animasi (DVD Version) Belajar Mengeja dan Menyebut ! ---------- Sebuah Video Pendidikan Dalam ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Abdul Malik al- Ansori',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Abdul Malik al- Ansori',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title:
                    'Mari Belajar Mengeja | Dunia Haiwan | Membaca Suku Kata | Bahasa Melayu | Melayu dan English',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/fU9rv5nA1w8/mqdefault_6s.webp?du=3000&sqp=CNWtw_4F&rs=AOn4CLCheIZWx_pNDg9oIwy-3H7V181zbQ',
                },
                desc:
                    'Bellaiman : Video ini boleh membantu anak anak mengeja dan membaca sambil mengenali pelbagai binatang dan haiwan.',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Evan You',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Evan You',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title:
                    'Literasi Bahasa Melayu - Konstruk 2 LINUS - Membaca & Menulis Tahap 1 Sekolah Rendah',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/XJrvdpNv6NE/mqdefault_6s.webp?du=3000&sqp=CPfCw_4F&rs=AOn4CLCzW7mcb86qeoE_S3uM05-6D-M-pQ',
                },
                desc:
                    'Literasi Bahasa Melayu - Konstruk 2 LINUS - Membaca & Menulis Tahap 1 Sekolah Rendah SK PENGKALAN TENTERA DARAT ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title: 'Belajar Baca Berita | SEISMIK Challenge',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/F4pPhtVM7Y4/mqdefault_6s.webp?du=3000&sqp=CNCaw_4F&rs=AOn4CLD4WktBX5JPywqSjBNv4YjS-JSiYg',
                },
                desc:
                    'Dalam SEISMIK Challenge kali ini, kitorang dah pun cabar Zaidi, Amin dan Brenda untuk jadi news presenter dalam Bulletin ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title: 'Belajar Mengeja | Belajar Membaca | Video Edukasi Anak',
                image: {
                    medium:
                        'https://i.ytimg.com/vi/DuPLmq4MWUI/hq720.jpg?sqp=-oaymwEZCOgCEMoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLBR7x_0E2RoBK_nTK0hqL758gSlCg',
                },
                desc:
                    'Belajar mengeja huruf abjad, belajar membaca untuk anak tk lengkap diantaranya: belajar membaca 3 huruf, belajar membaca 5 ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/-FPD_b_Zuik/mqdefault_6s.webp?du=3000&sqp=CIG8w_4F&rs=AOn4CLCEyEzy8p4dSsX24X8FnYuERZwJzg',
                },
                desc:
                    'Mohon teman-teman bantu subscribe channel kami agar lebih semangat untuk buat video baru. belajar ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },

            {
                id: 1,
                title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/DXF6AQ_QS9s/mqdefault_6s.webp?du=3000&sqp=CInJw_4F&rs=AOn4CLDbgGYR_XlFqGNfuIjbEjKoHqLeIg',
                },
                desc:
                    'Mohon teman-teman bantu subscribe channel kami agar lebih semangat untuk buat video baru. belajar ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
        ],
    },
    details: {
        is_loading: true,
        status: true,
        data: {
            id: 1,
            title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',

            image: {
                medium:
                    'https://i.ytimg.com/an_webp/QHBpSxC2Iv0/mqdefault_6s.webp?du=3000&sqp=CMPCw_4F&rs=AOn4CLDOkXEFDV0RNKDJ4JRvbL1-9m8Ndw',
            },
            video: [
                {
                    src: 'https://vjs.zencdn.net/v/oceans.mp4',
                    type: 'video/mp4',
                    label: '720P',
                },
                {
                    src: 'https://vjs.zencdn.net/v/oceans.mp4',
                    type: 'video/mp4',
                    label: '480P',
                },
            ],
            desc: `Understanding eLearning is simple. eLearning is learning utilizing electronic technologies to access educational curriculum outside of a traditional classroom.  In most cases, it refers to a course, program or degree delivered completely online.

                There are many terms used to describe learning that is delivered online, via the internet, ranging from Distance Education, to computerized electronic learning, online learning, internet learning and many others. We define eLearning as courses that are specifically delivered via the internet to somewhere other than the classroom where the professor is teaching. It is not a course delivered via a DVD or CD-ROM, video tape or over a television channel. It is interactive in that you can also communicate with your teachers, professors or other students in your class. Sometimes it is delivered live, where you can “electronically” raise your hand and interact in real time and sometimes it is a lecture that has been prerecorded. There is always a teacher or professor interacting /communicating with you and grading your participation, your assignments and your tests. eLearning has been proven to be a successful method of training and education is becoming a way of life for many citizens in North Carolina.`,
            created_at: '2020-12-13T06:09:15.000000Z',
            created_by: {
                full_name: 'Cikgu Hassan al Hussin',
            },
            modified_at: '2020-12-13T06:09:15.000000Z',
            modified_by: {
                full_name: 'Cikgu Hassan al Hussin',
            },
            total_view: 122321,
            watching_progress: 10,
            duration: '8:35',
            total_like: 2132,
            total_comment: 30,
        },
    },
    video_comments: {
        is_loading: true,
        data: [
            {
                id: 1,
                user: {
                    slug: 'aiman',
                    first_name: 'Aiman',
                    full_name: 'Aiman Bahari',
                },
                comment:
                    'Video yang bagus dapat membantu mempertingkat kemahiran dan pengetahuan',
                total_reply: 5,

                avatar: null,
                replies: [],
            },
            {
                id: 2,
                user: {
                    slug: 'zulaika',
                    first_name: 'Siti Zulaika',
                    full_name: 'Siti Zulaika Hussin',
                },
                comment: 'Mantap video ini,terima kasih cikgu',
                total_reply: 1,

                avatar: null,
                replies: [],
            },
        ],
    },
    update_watching_video: {
        is_loading: true,
    },
    list_up_next: {
        is_loading: true,
        total_records: 0,
        total_retrieved: 0,
        data: [
            {
                id: 1,
                title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',

                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/QHBpSxC2Iv0/mqdefault_6s.webp?du=3000&sqp=CMPCw_4F&rs=AOn4CLDOkXEFDV0RNKDJ4JRvbL1-9m8Ndw',
                },
                desc:
                    'Mohon teman-teman bantu subscribe channel kami agar lebih semangat untuk buat video baru. belajar ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 2,
                title: 'Mari Belajar Suku Kata - 3D Animasi (DVD Version)',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/ikRNErwR0pc/mqdefault_6s.webp?du=3000&sqp=CNykw_4F&rs=AOn4CLBTZeKFWa-Qqj_0mdERjS2HyFSIBg',
                },
                desc:
                    'Mari Belajar Suku Kata - 3D Animasi (DVD Version) Belajar Mengeja dan Menyebut ! ---------- Sebuah Video Pendidikan Dalam ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Abdul Malik al- Ansori',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Abdul Malik al- Ansori',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title:
                    'Mari Belajar Mengeja | Dunia Haiwan | Membaca Suku Kata | Bahasa Melayu | Melayu dan English',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/fU9rv5nA1w8/mqdefault_6s.webp?du=3000&sqp=CNWtw_4F&rs=AOn4CLCheIZWx_pNDg9oIwy-3H7V181zbQ',
                },
                desc:
                    'Bellaiman : Video ini boleh membantu anak anak mengeja dan membaca sambil mengenali pelbagai binatang dan haiwan.',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Evan You',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Evan You',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title:
                    'Literasi Bahasa Melayu - Konstruk 2 LINUS - Membaca & Menulis Tahap 1 Sekolah Rendah',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/XJrvdpNv6NE/mqdefault_6s.webp?du=3000&sqp=CPfCw_4F&rs=AOn4CLCzW7mcb86qeoE_S3uM05-6D-M-pQ',
                },
                desc:
                    'Literasi Bahasa Melayu - Konstruk 2 LINUS - Membaca & Menulis Tahap 1 Sekolah Rendah SK PENGKALAN TENTERA DARAT ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title: 'Belajar Baca Berita | SEISMIK Challenge',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/F4pPhtVM7Y4/mqdefault_6s.webp?du=3000&sqp=CNCaw_4F&rs=AOn4CLD4WktBX5JPywqSjBNv4YjS-JSiYg',
                },
                desc:
                    'Dalam SEISMIK Challenge kali ini, kitorang dah pun cabar Zaidi, Amin dan Brenda untuk jadi news presenter dalam Bulletin ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title: 'Belajar Mengeja | Belajar Membaca | Video Edukasi Anak',
                image: {
                    medium:
                        'https://i.ytimg.com/vi/DuPLmq4MWUI/hq720.jpg?sqp=-oaymwEZCOgCEMoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLBR7x_0E2RoBK_nTK0hqL758gSlCg',
                },
                desc:
                    'Belajar mengeja huruf abjad, belajar membaca untuk anak tk lengkap diantaranya: belajar membaca 3 huruf, belajar membaca 5 ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
            {
                id: 1,
                title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/-FPD_b_Zuik/mqdefault_6s.webp?du=3000&sqp=CIG8w_4F&rs=AOn4CLCEyEzy8p4dSsX24X8FnYuERZwJzg',
                },
                desc:
                    'Mohon teman-teman bantu subscribe channel kami agar lebih semangat untuk buat video baru. belajar ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },

            {
                id: 1,
                title: 'Belajar Membaca dan Mengeja Ayat Mudah [PART 1]',
                image: {
                    medium:
                        'https://i.ytimg.com/an_webp/DXF6AQ_QS9s/mqdefault_6s.webp?du=3000&sqp=CInJw_4F&rs=AOn4CLDbgGYR_XlFqGNfuIjbEjKoHqLeIg',
                },
                desc:
                    'Mohon teman-teman bantu subscribe channel kami agar lebih semangat untuk buat video baru. belajar ...',
                created_at: '2020-12-13T06:09:15.000000Z',
                created_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                modified_at: '2020-12-13T06:09:15.000000Z',
                modified_by: {
                    full_name: 'Cikgu Hassan al Hussin',
                },
                total_view: 2232,
                watching_progress: 10,
                duration: '8:35',
            },
        ],
    },
    should_call_comment_list: false,
};

const todos = (state = initialState, action: any) => {
    switch (action.type) {
        case type.GET_VIDEO_LIST_ASYNC:
            state.list.is_loading = action.is_loading;
            if (action.is_loading == false) {
                state.list.data = action.data;
                state.list.total_records = action.total_records;
                state.list.total_retrieved = action.total_retrieved;
            }
            if (action.status == false) {
                state.list.data = [];
            }

            return { ...state };
        case type.GET_VIDEO_DETAILS_ASYNC:
            state.details.is_loading = action.is_loading;
            if (action.is_loading == false) {
                state.details.data = action.data;
                state.details.status = action.status;
            }

            return { ...state };
        case type.GET_VIDEO_COMMENT_LIST_ASYNC:
            state.video_comments.is_loading = action.is_loading;
            if (action.is_loading == false) {
                state.video_comments.data = action.data;
                state.video_comments.total_records = action.total_records;
                state.video_comments.total_retrieved = action.total_retrieved;
                state.should_call_comment_list = false;
            }
            if (action.status == false) {
                state.video_comments.data = [];
                state.video_comments.total_records = 0;
                state.video_comments.total_retrieved = 0;
            }

            return { ...state };
        case type.SHOULD_CALL_COMMENT_LIST:
            state.should_call_comment_list = action.status;

            return { ...state };
        case type.UPDATE_WATCHING_VIDEO_ASYNC:
            state.update_watching_video.is_loading = action.is_loading;

            return { ...state };
        case type.GET_VIDEO_LIST_UPNEXT_ASYNC:
            state.list_up_next.is_loading = action.is_loading;
            if (action.is_loading == false) {
                state.list_up_next.data = action.data;
                state.list_up_next.total_records = action.total_records;
                state.list_up_next.total_retrieved = action.total_retrieved;
            }
            if (action.status == false) {
                state.list_up_next.data = [];
            }

            return { ...state };
        default:
            return state;
    }
};
export default todos;
