import React from 'react';
import { config } from '../../../app.config';
import Axios from 'axios';
import {
    authHeaderAppJson,
    headerAppJson,
} from '../../../SERVICES/axios-header';
import queryString from 'query-string';
import { getErrorMessageFromAxios } from '../../../SERVICES/user-service';
import { CANCEL } from 'redux-saga';

// export const getVideoList = (action: any) => {
//     return Axios.get(`${config.SERVER_ENDPOINT}/video/list`, {
//         ...authHeaderAppJson(),
//         params: { ...action.params },
//     })
//         .then((resp) => resp.data)
//         .catch((err) => {
//             getErrorMessageFromAxios(err);
//             throw err;
//         });
// };

export const getEbookListPromise = (action: any) => {
    return new Promise((resolve: any, reject: any) => {
        const params = {
            ...action.params,
        };

        const url = `${config.SERVER_ENDPOINT}/ebook/${
            action.is_login ? 'auth/' : ''
        }list`;

        var axiosConfig: any = {
            method: 'post',
            url: url,
            headers: action.is_login ? authHeaderAppJson().headers : null,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                getErrorMessageFromAxios(err);
                throw err;
            });
    });
};

export const getEbookList = (action: any) => {
    const source = Axios.CancelToken.source();
    const params = {
        ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/ebook/${
        action.is_login ? 'auth/' : ''
    }list`;

    var axiosConfig: any = {
        method: 'post',
        url: url,
        headers: action.is_login
            ? authHeaderAppJson().headers
            : headerAppJson().headers,
        data: params,
        cancelToken: source.token,
    };
    //  return new Promise((resolve: any, reject: any) => {
    const request: any = Axios(axiosConfig)
        .then((resp) => {
            // resolve(resp.data);
            return resp.data;
        })
        .catch((err) => {
            getErrorMessageFromAxios(err);
            throw err;
        });

    request[CANCEL] = () => source.cancel();
    return request;
    // resolve(request);
    //   });
};

export const getEbookDetails = (action: any) => {
    return new Promise((resolve: any, reject: any) => {
        const params = {
            ...action.params,
        };

        const url = `${config.SERVER_ENDPOINT}/ebook/${
            action.is_login ? 'auth/' : ''
        }${action.ebook_slug}`;

        var axiosConfig: any = {
            method: 'get',
            url: url,
            headers: action.is_login
                ? authHeaderAppJson().headers
                : headerAppJson().headers,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                getErrorMessageFromAxios(err);
                throw err;
            });
    });
};
