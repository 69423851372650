import { user } from './types';
import { setUserSession } from '../SERVICES/user-service';

const initialState: any = {
    login: {
        status: false, //logged or not
    },
    user: {
        is_loading: true,
        // id: 1,
        // avatar: null,
        // email: 'izzuddinraffar@gmail.com',
        // first_name: 'Mohamad Izzuddin',
        // full_name: 'Mohamad Izzuddin Ab Raffar',
        // last_name: 'Ab Raffar',
        // bio: null,
        // role: null,
    },
    user_details: {
        is_loading: true,
    },
    user_socket: null,
    notification: {
        is_loading: true,
        total_retrieved: 10,
        total_records: 40,
        total_unread: 3,
        data: [
            {
                id: 1,
                notify_by: {
                    slug: 'aiman',
                    first_name: 'Aiman',
                    full_name: 'Aiman Bahari',
                    avatar: null,
                },
                notify_type: 'reply_comment',
                notify_content:
                    'Video yang bagus dapat membantu mempertingkat kemahiran dan pengetahuan', // <-- content from comment
                notify_content_id: 2, // <-- id comment
                category: 'video_comment', // <-- group by module mana
                route: {
                    slug:
                        'pecutan-akhir-spm-2020-bahasa-melayu-cikgu-hasfariza-bahagian-3',
                    title:
                        'Pecutan Akhir SPM 2020 | Bahasa Melayu - Cikgu Hasfariza Bahagian 1',
                },

                created_at: '2020-12-13T06:09:15.000000Z',

                unread: true,
            },
            {
                id: 2,
                notify_by: {
                    slug: 'zubaidah',
                    first_name: 'Zubaidah',
                    full_name: 'Zubaidah Aisyah',
                    avatar: null,
                },
                notify_type: 'new_comment',
                notify_content: 'Nice job teacher',
                notify_content_id: 3,
                category: 'video_comment',
                route: {
                    slug:
                        'pecutan-akhir-spm-2020-bahasa-melayu-cikgu-hasfariza-bahagian-3',
                    title: 'Pecutan Akhir SPM 2020 | Bahasa Inggeris - Part 2',
                },
                created_at: '2020-12-13T06:09:15.000000Z',

                unread: false,
            },
        ],
    },
    activity_log: {
        is_loading: true,
        data: [
            {
                created_at: '2020-12-13T06:09:15.000000Z',
                activities: [
                    {
                        id: 1,
                        notify_by: {
                            slug: 'aiman',
                            first_name: 'Aiman',
                            full_name: 'Aiman Bahari',
                        },

                        message:
                            'Aiman komen pada video Mari Belajar Mengaji : Video yang bagus dapat membantu mempertingkat kemahiran dan pengetahuan',
                        total_reply: 5,
                        created_at: '2020-12-13T06:09:15.000000Z',
                        avatar: null,
                    },
                    {
                        id: 2,
                        notify_by: {
                            slug: 'zulaika',
                            first_name: 'Siti Zulaika',
                            full_name: 'Siti Zulaika Hussin',
                        },
                        message:
                            'Siti Zulaika komen pada video Mari Belajar Mengaji : Mantap video ini,terima kasih cikgu',
                        created_at: '2020-12-13T06:09:15.000000Z',
                        total_reply: 1,

                        avatar: null,
                    },
                ],
            },
            {
                created_at: '2020-12-13T06:09:15.000000Z',
                activities: [
                    {
                        id: 1,
                        notify_by: {
                            slug: 'aiman',
                            first_name: 'Aiman',
                            full_name: 'Aiman Bahari',
                        },

                        message:
                            'Aiman komen pada video Mari Belajar Mengaji : Video yang bagus dapat membantu mempertingkat kemahiran dan pengetahuan',
                        total_reply: 5,
                        created_at: '2020-12-13T06:09:15.000000Z',
                        avatar: null,
                    },
                    {
                        id: 2,
                        notify_by: {
                            slug: 'zulaika',
                            first_name: 'Siti Zulaika',
                            full_name: 'Siti Zulaika Hussin',
                        },
                        message:
                            'Siti Zulaika komen pada video Mari Belajar Mengaji : Mantap video ini,terima kasih cikgu',
                        created_at: '2020-12-13T06:09:15.000000Z',
                        total_reply: 1,

                        avatar: null,
                    },
                ],
            },
        ],
    },
    search_suggestion: {
        is_loading: true,
        data: [],
        total_records: 0,
        total_retrieved: 0,
    },
};

const todos = (state = initialState, action: any) => {
    switch (action.type) {
        case user.USER_LOGIN_STATUS:
            state.login.status = action.is_login;
            if (state.login.status && action.user) {
                setUserSession(action.user);
            }

            return { ...state };
        case user.USER_INIT_ASYNC:
            state.user.is_loading = action.is_loading;
            if (!action.is_loading) {
                // if (action.data.role) {
                //     action.data.role = {
                //         value: action.data.role.value,
                //         label: action.data.role.name,
                //     };
                // }
                // if (action.data.level) {
                //     action.data.level = {
                //         value: action.data.level.value,
                //         label: action.data.level.name,
                //     };
                // }
                // if (action.data.gender) {
                //     action.data.gender = {
                //         value: action.data.gender.value,
                //         label: action.data.gender.name,
                //     };
                // }
                state.user = { ...state.user, ...action.data };
                // state.user.avatar = {
                //     small: `https://robohash.org/${state.user?.first_name}${state.user?.id}?set=set3`.replace(
                //         / /g,
                //         ''
                //     ),
                //     medium: `https://robohash.org/${state.user?.first_name}${state.user?.id}?set=set3`.replace(
                //         / /g,
                //         ''
                //     ),
                //     large: `https://robohash.org/${state.user?.first_name}${state.user?.id}?set=set3`.replace(
                //         / /g,
                //         ''
                //     ),
                // };
            }
            state.user_details = state.user;
            return { ...state };

        case user.GET_USER_SOCKET:
            state.user_socket = action.user_socket;
            return { ...state };
        case user.GET_SEARCH_SUGGESTION_LIST_ASYNC:
            state.search_suggestion.is_loading = action.is_loading;
            if (action.is_loading == false) {
                state.search_suggestion.data = action.data;
                state.search_suggestion.total_records = action.total_records;
                state.search_suggestion.total_retrieved =
                    action.total_retrieved;
            }
            if (action.status == false) {
                state.search_suggestion.data = [];
            }

            return { ...state };

        case user.GET_NOTIFICATION_LIST_ASYNC:
            state.notification.is_loading = action.is_loading;
            if (action.is_loading == false) {
                state.notification.data = action.data;
                state.notification.total_records = action.total_records;
                state.notification.total_retrieved = action.total_retrieved;
                state.notification.total_unread = action.total_unread;
            }
            if (action.status == false) {
                state.notification.data = [];
            }

            return { ...state };
        default:
            return state;
    }
};
export default todos;
