import MainRouter from '../ROUTERS/index';

import Theme from './App-theme';
import Themev5 from './App-theme-v5';
function App(props: any) {
  return (
    <Themev5>
      <Theme>
        <MainRouter />
      </Theme>
    </Themev5>
  );
}

export default App;
