import { call, takeLatest, put, delay } from 'redux-saga/effects';
import { type } from './type';
import { getVideoList } from './axios';

function* watch_getVideoList(action: any) {
    try {
        yield put({
            type: type.GET_TEACHER_VIDEO_LIST_ASYNC,
            is_loading: true,
        });
        const data = yield call(getVideoList, action);
        yield put({
            type: type.GET_TEACHER_VIDEO_LIST_ASYNC,
            data: data.data,
            status: data.status,
            is_loading: false,
            total_records: data.total_records,
            total_retrieved: data.total_retrieved,
        });
    } catch (error) {
        throw error;
    }
}

export function* watchVideosSagas() {
    yield takeLatest(type.GET_TEACHER_VIDEO_LIST, watch_getVideoList);
}
