import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const allowedLanguages = ['en', 'my'];

const defaultLng = 'my';
let lng = defaultLng;

const storageLanguage = localStorage.getItem('language');
if (
  storageLanguage &&
  allowedLanguages.indexOf(storageLanguage) > -1 &&
  storageLanguage
) {
  lng = storageLanguage;
}

//console.log(storageLanguage);

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng,
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/assets/translations/{{lng}}.json',
      allowMultiLoading: true,
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
