import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MainWrapper from '../CONTAINERS/layouts/index-wrapper';

import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

import '../i18n';
import { PrivateRoute } from './private-route';
import { CheckPrivateRoute } from './check-private-route';

const Welcome = lazy(() => import('../PAGES/welcome/index'));
const WelcomeLearningZone = lazy(
  () => import('../PAGES/welcome-learning-zone/index')
);
const WelcomePecutanAkhir = lazy(
  () => import('../PAGES/video-pecutan-akhir/index')
);
const WelcomeVideoPDPR = lazy(() => import('../PAGES/video-pdpr/index'));
const VideoPDPRTopicView = lazy(
  () => import('../PAGES/video-pdpr-topic-view/index')
);
const Login = lazy(() => import('../PAGES/login/index'));
const ForgotPassword = lazy(() => import('../PAGES/forgot-password/index'));
const ResetPassword = lazy(() => import('../PAGES/reset-password/index'));
const Register = lazy(() => import('../PAGES/register/index'));
const Logout = lazy(() => import('../PAGES/logout/index'));
const AboutUs = lazy(() => import('../PAGES/about-us/index'));
const VideoList = lazy(() => import('../PAGES/video-list/index'));
const LearningProgress = lazy(() => import('../PAGES/learning-progress/index'));
const VideoWatch = lazy(() => import('../PAGES/video-watch/index'));
const CreateSession = lazy(() => import('../PAGES/create-session/index'));
const NotFoundPage = lazy(() => import('../PAGES/404/index'));
const likedVideo = lazy(() => import('../PAGES/liked-video/index'));
const VerifyEmail = lazy(() => import('../PAGES/verify-email/index'));
const User = lazy(() => import('../PAGES/user/index'));
const PrivacyPolicy = lazy(() => import('../PAGES/privacy-policy/index'));
const eBook = lazy(() => import('../PAGES/ebook/index'));
const eBookDetails = lazy(() => import('../PAGES/ebook-details/index'));
const UserViewOnly = lazy(() => import('../PAGES/user-view-only/index'));
const Certification = lazy(() => import('../PAGES/certification/index'));

function MainRouter() {
  return (
    <MainWrapper>
      <Suspense
        fallback={
          <div className='loading-wrapper'>
            <Grid item xs={8} sm={5}>
              <Skeleton height={200} />
              <Skeleton height={50} />
              <Skeleton height={50} animation={false} />
              <Skeleton height={50} animation='wave' />
            </Grid>
          </div>
        }
      >
        <Switch>
          <Route exact path='/' component={Welcome} />
          <Route
            exact
            path='/welcome-learning'
            component={WelcomeLearningZone}
          />
          <Route
            exact
            path='/videos-pdpr/:subject_slug?'
            component={WelcomeVideoPDPR}
          />
          <Route
            exact
            path='/video-pdpr/:topic_slug'
            component={VideoPDPRTopicView}
          />
          <Route
            exact
            path='/videos-pecutan-akhir/:subject_slug?'
            component={WelcomePecutanAkhir}
          />
          <PrivateRoute exact path='/logout' component={Logout} />
          <PrivateRoute exact path='/e-cert' component={Certification} />

          <CheckPrivateRoute exact path='/login' component={Login} />
          <CheckPrivateRoute exact path='/register' component={Register} />
          <CheckPrivateRoute
            exact
            path='/forgot-password'
            component={ForgotPassword}
          />
          <CheckPrivateRoute
            exact
            path='/reset-password/:token'
            component={ResetPassword}
          />
          <PrivateRoute exact path='/user/:view' component={User} />
          <Route exact path='/user/:view/:user_slug' component={UserViewOnly} />

          <Route exact path='/video-list/:keyword?' component={VideoList} />
          <Route exact path='/about-us' component={AboutUs} />
          <PrivateRoute
            exact
            path='/learning-progress/:view?'
            component={LearningProgress}
          />
          <PrivateRoute
            exact
            path='/liked-video/:view?'
            component={likedVideo}
          />
          <Route exact path='/ebook/:view?' component={eBook} />
          <Route
            exact
            path='/ebook-view/:ebook_slug'
            component={eBookDetails}
          />
          <Route exact path='/watch/:video_slug' component={VideoWatch} />
          <Route exact path='/verify-email/:email' component={VerifyEmail} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          {/* <Route
                        exact
                        path='/create-session'
                        component={CreateSession}
                    /> */}
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </MainWrapper>
  );
}

export default MainRouter;
