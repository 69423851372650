import { all } from 'redux-saga/effects';

import { watchUserSagas } from './user-saga';
import { watchVideosSagas } from '../PAGES/video-list/store/saga';
import { watchVideosSagas as watchLearningProgressSagas } from '../PAGES/learning-progress/store/saga';
import { watchVideosSagas as watchTeacherVideosSagas } from '../PAGES/user/videos/store/saga';
import { watchStudentCertSagas } from '../PAGES/user/certificate/store/saga';

import { watchUserSagas as watchUserViewOnlySagas } from '../PAGES/user-view-only/store/saga';
import { watchEbookSagas } from '../PAGES/ebook/store/saga';

export default function* rootSaga() {
  yield all([
    watchUserSagas(),
    watchVideosSagas(),
    watchTeacherVideosSagas(),
    watchUserViewOnlySagas(),
    watchLearningProgressSagas(),
    watchEbookSagas(),
    watchStudentCertSagas(),
  ]);
}
