import React from 'react';
import { config } from '../app.config';
import Axios from 'axios';
import { CANCEL } from 'redux-saga';
import { authHeaderAppJson, headerAppJson } from '../SERVICES/axios-header';
import queryString from 'query-string';
import { getErrorMessageFromAxios } from '../SERVICES/user-service';
export const getUserInit = (action: any) => {
    return Axios.get(`${config.SERVER_ENDPOINT}/get-user`, {
        ...authHeaderAppJson(),
    })
        .then((resp) => resp.data)
        .catch((err) => {
            const error = getErrorMessageFromAxios(err);

            if (
                error.trim() == 'Unauthenticated.' ||
                err.message.trim() == 'Request failed with status code 401'
            ) {
                localStorage.removeItem('user');
                window.location.href = '/';
            }

            throw err;
        });
};

export const userLogout = (action: any) => {
    const params = JSON.stringify({
        token: action.token,
    });

    const url = `${config.SERVER_ENDPOINT}/logout`;

    var axiosConfig: any = {
        method: 'get',
        url: url,
        headers: authHeaderAppJson().headers,
    };
    return Axios(axiosConfig)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getSchoolList = (action: any) => {
    return new Promise((resolve, reject) => {
        const params = action.params;

        const url = `${config.SERVER_ENDPOINT}/school/list`;

        var axiosConfig: any = {
            method: 'post',
            url: url,
            headers: headerAppJson().headers,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                throw err;
            });
    });
};

export const getStateList = (action: any) => {
    return new Promise((resolve, reject) => {
        // const params = action.params;

        const url = `${config.SERVER_ENDPOINT}/state/list`;

        var axiosConfig: any = {
            method: 'get',
            url: url,
            headers: headerAppJson().headers,
            // data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                throw err;
            });
    });
};

export const getVideoList = (action: any) => {
    const source = Axios.CancelToken.source();
    const params = {
        ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/video/${
        action.is_login ? 'auth/' : ''
    }list`;

    var axiosConfig: any = {
        method: 'post',
        url: url,
        headers: action.is_login ? authHeaderAppJson().headers : null,
        data: params,
        cancelToken: source.token,
    };
    //  return new Promise((resolve: any, reject: any) => {
    const request: any = Axios(axiosConfig)
        .then((resp) => {
            // resolve(resp.data);
            return resp.data;
        })
        .catch((err) => {
            getErrorMessageFromAxios(err);
            throw err;
        });

    request[CANCEL] = () => source.cancel();
    return request;
    // resolve(request);
    //   });
};

export const getNotificationList = (action: any) => {
    return new Promise((resolve, reject) => {
        const params = action.params;

        const url = `${config.SERVER_ENDPOINT}/notification/list2`;

        var axiosConfig: any = {
            method: 'post',
            url: url,
            headers: authHeaderAppJson().headers,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                throw err;
            });
    });
};

export const updateNotification = (action: any) => {
    return new Promise((resolve, reject) => {
        // const params = action.params;

        const url = `${config.SERVER_ENDPOINT}/notification/update/${action.id}`;

        var axiosConfig: any = {
            method: 'put',
            url: url,
            headers: authHeaderAppJson().headers,
            // data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                throw err;
            });
    });
};

export const getTotalUnreadNotification = (action: any) => {
    return new Promise((resolve, reject) => {
        // const params = action.params;

        const url = `${config.SERVER_ENDPOINT}/notification/list/unread`;

        var axiosConfig: any = {
            method: 'get',
            url: url,
            headers: authHeaderAppJson().headers,
            // data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                throw err;
            });
    });
};
