import React from 'react';
import { config } from '../../../app.config';
import Axios from 'axios';
import {
    authHeaderAppJson,
    headerAppJson,
} from '../../../SERVICES/axios-header';
import queryString from 'query-string';
import { getErrorMessageFromAxios } from '../../../SERVICES/user-service';

// export const getVideoList = (action: any) => {
//     return Axios.get(`${config.SERVER_ENDPOINT}/video/list`, {
//         ...authHeaderAppJson(),
//         params: { ...action.params },
//     })
//         .then((resp) => resp.data)
//         .catch((err) => {
//             getErrorMessageFromAxios(err);
//             throw err;
//         });
// };

export const getVideoList = (action: any) => {
    return new Promise((resolve: any, reject: any) => {
        const params = {
            ...action.params,
        };

        const url = `${config.SERVER_ENDPOINT}/video/${
            action.is_login ? 'auth/' : ''
        }list`;

        var axiosConfig: any = {
            method: 'post',
            url: url,
            headers: action.is_login ? authHeaderAppJson().headers : null,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                getErrorMessageFromAxios(err);
                throw err;
            });
    });
};

export const getVideoDetails = (action: any) => {
    const params = {
        ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/video/${
        action.is_login ? 'auth/' : ''
    }${action.video_slug}`;

    var axiosConfig: any = {
        method: 'get',
        url: url,
        headers: action.is_login ? authHeaderAppJson().headers : null,
    };
    return Axios(axiosConfig)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            getErrorMessageFromAxios(err);
            throw err;
        });
};

export const getCommentList = (action: any) => {
    return new Promise((resolve: any, reject: any) => {
        const params = {
            ...action.params,
            sort: action.sortBy ? action.sortBy : 'asc',
        };

        const url = `${config.SERVER_ENDPOINT}/video/comment/list2/${action.video_slug}`;

        var axiosConfig: any = {
            method: 'post',
            url: url,
            headers: authHeaderAppJson().headers,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                getErrorMessageFromAxios(err);
                throw err;
            });
    });
};

export const addComment = (action: any) => {
    return new Promise((resolve: any, reject: any) => {
        const params = {
            ...action.params,
        };

        const url = `${config.SERVER_ENDPOINT}/video/comment/add/${action.video_id}`;

        var axiosConfig: any = {
            method: 'post',
            url: url,
            headers: authHeaderAppJson().headers,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                getErrorMessageFromAxios(err);
                throw err;
            });
    });
};

export const deleteComment = (action: any) => {
    return new Promise((resolve: any, reject: any) => {
        const params = {
            ...action.params,
        };

        const url = `${config.SERVER_ENDPOINT}/video/comment/delete/${action.comment_id}`;

        var axiosConfig: any = {
            method: 'post',
            url: url,
            headers: authHeaderAppJson().headers,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                getErrorMessageFromAxios(err);
                throw err;
            });
    });
};

export const updateComment = (action: any) => {
    return new Promise((resolve: any, reject: any) => {
        const params = {
            ...action.params,
        };

        const url = `${config.SERVER_ENDPOINT}/video/comment/update/${action.comment_id}`;

        var axiosConfig: any = {
            method: 'post',
            url: url,
            headers: authHeaderAppJson().headers,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                getErrorMessageFromAxios(err);
                throw err;
            });
    });
};

export const updateWatchingVideo = (action: any) => {
    const params = {
        ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/watch-video/update/${action.video_id}`;

    var axiosConfig: any = {
        method: 'put',
        url: url,
        headers: authHeaderAppJson().headers,
        data: params,
    };
    return Axios(axiosConfig)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            getErrorMessageFromAxios(err);
            throw err;
        });
};

export const getVideoListUPNEXT = (action: any) => {
    return new Promise((resolve: any, reject: any) => {
        const params = {
            ...action.params,
        };

        const url = `${config.SERVER_ENDPOINT}/video/${
            action.is_login ? 'auth/' : ''
        }list/next/${action.video_slug}`;

        var axiosConfig: any = {
            method: 'post',
            url: url,
            headers: action.is_login ? authHeaderAppJson().headers : null,
            data: params,
        };
        return Axios(axiosConfig)
            .then((resp) => {
                resolve(resp.data);
            })
            .catch((err) => {
                getErrorMessageFromAxios(err);
                throw err;
            });
    });
};
