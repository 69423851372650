import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import './framework.scss';

export default function ThemeManager(props) {
  const dispatch = useDispatch();
  const redux = useSelector((state) => state.redux);
  // A custom theme for this app
  const themeState = redux.app.default_theme;
  const palletType = themeState == 'dark' ? 'dark' : 'light';
  const mainPrimaryColor = themeState == 'dark' ? '#2d88ff' : '#2D6FD8';
  const mainSecondaryColor = themeState == 'dark' ? '#f02849' : '#f02849';

  const theme = createTheme({
    palette: {
      type: palletType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
    },
    typography: {
      fontFamily: "'Axiforma Regular', sans-serif",
      h1: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h2: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h3: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h4: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h5: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h6: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      body1: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      button: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {props.children}
    </ThemeProvider>
  );
}
