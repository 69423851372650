import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export default function ThemeManager(props: any) {
  const dispatch = useDispatch();
  const redux = useSelector((state: any) => state.redux);
  // A custom theme for this app
  const themeState = redux.app.default_theme;
  const mainPrimaryColor = themeState == 'dark' ? '#2d88ff' : '#2D6FD8';
  const mainSecondaryColor = themeState == 'dark' ? '#f02849' : '#f02849';

  const theme = createTheme({
    palette: {
      mode: themeState,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: "'Axiforma Regular', sans-serif",
      h1: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h2: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h3: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h4: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h5: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      h6: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      body1: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
      button: {
        fontFamily: "'Axiforma SemiBold', sans-serif",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {props.children}
    </ThemeProvider>
  );
}
