import { call, takeLatest, put, delay } from 'redux-saga/effects';
import { type } from './type';
import { getEbookList, getEbookDetails } from './axios';

function* watch_getEbookList(action: any) {
    try {
        yield put({
            type: type.GET_EBOOK_LIST_ASYNC,
            is_loading: true,
        });
        const data = yield call(getEbookList, action);
        yield put({
            type: type.GET_EBOOK_LIST_ASYNC,
            data: data.data,
            status: data.status,
            is_loading: false,
            total_records: data.total_records,
            total_retrieved: data.total_retrieved,
        });
    } catch (error) {
        throw error;
    }
}

function* watch_getEbookDetails(action: any) {
    try {
        yield put({
            type: type.GET_EBOOK_DETAILS_ASYNC,
            is_loading: true,
        });
        const data = yield call(getEbookDetails, action);
        yield put({
            type: type.GET_EBOOK_DETAILS_ASYNC,
            data: data.data,
            status: data.status,
            is_loading: false,
        });
    } catch (error) {
        throw error;
    }
}

export function* watchEbookSagas() {
    yield takeLatest(type.GET_EBOOK_LIST, watch_getEbookList);
    yield takeLatest(type.GET_EBOOK_DETAILS, watch_getEbookDetails);
}
