import { drawer } from './types';

const initialState = {
    status: false, // collape expand
};

const todos = (state = initialState, action: any) => {
    switch (action.type) {
        case drawer.DRAWER_TOOGLE:
            state.status = action.status;
            return { ...state };
        default:
            return state;
    }
};
export default todos;
