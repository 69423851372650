import { app } from './types';
import { setTheme } from '../SERVICES/user-service';
import { setLanguage, getLanguage } from '../SERVICES/user-service';
import { config } from '../app.config';

const initialState = {
    title: config.TITLE,
    subtitle: config.SUBTITLE,
    description: config.DESCRIPTION,
    client_endpoint: config.CLIENT_ENDPOINT,
    server_endpoint: config.SERVER_ENDPOINT,
    default_language: config.DEFAULT_LANGUAGE,
    keyword: '',
    is_searching: false,
    default_theme: config.DEFAULT_THEME,
    current_grid: null,
};

const todos = (state = initialState, action: any) => {
    switch (action.type) {
        case app.CHANGE_THEME:
            state.default_theme = action.theme;
            setTheme(action.theme);
            return { ...state };
        case app.CHANGE_LANGUAGE:
            state.default_language = action.language;
            setLanguage(action.language);
            return { ...state };
        case app.CHANGE_GRID_VIEW:
            state.current_grid = action.grid;
            return { ...state };
        case app.SET_KEYWORD_MAIN_SEARCH:
            state.keyword = action.keyword;
            return { ...state };
        default:
            return state;
    }
};
export default todos;
