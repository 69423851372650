import { type } from './type';

const initialState: any = {
  dashboard_my_progress: {
    is_loading: true,
    data: {
      total_view: 11,
      total_like: 2,
      total_comment: 3,
    },
  },
  list: {
    is_loading: true,
    data: [
      {
        id: 1,
        name: 'Bahasa Melayu',
        level: 'SPM',
        progress: 100,
        cert_status: true,
        cert_path:
          'https://ptrsmedia.s3-ap-southeast-1.amazonaws.com/media/ebook/ptrs_spm_2020/bahasa_melayu/PTRS_BM_SPM_2020_Kandungan.pdf',
      },
      {
        id: 2,
        name: 'English',
        level: 'SPM',
        progress: 90,
        cert_status: false,
        cert_path:
          'https://ptrsmedia.s3-ap-southeast-1.amazonaws.com/media/ebook/ptrs_spm_2020/bahasa_melayu/PTRS_BM_SPM_2020_Kandungan.pdf',
      },
      {
        id: 2,
        name: 'Sains',
        level: 'SPM',
        progress: 40,
        cert_status: false,
        cert_path:
          'https://ptrsmedia.s3-ap-southeast-1.amazonaws.com/media/ebook/ptrs_spm_2020/bahasa_melayu/PTRS_BM_SPM_2020_Kandungan.pdf',
      },
      {
        id: 2,
        name: 'Math',
        level: 'SPM',
        progress: 10,
        cert_status: false,
        cert_path:
          'https://ptrsmedia.s3-ap-southeast-1.amazonaws.com/media/ebook/ptrs_spm_2020/bahasa_melayu/PTRS_BM_SPM_2020_Kandungan.pdf',
      },
    ],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case type.GET_STUDENT_CERT_LIST_ASYNC:
      state.list.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.list.data = action.data;
        state.list.total_records = action.total_records;
        state.list.total_retrieved = action.total_retrieved;
      }
      if (action.status == false) {
        state.list.data = [];
      }

      return { ...state };

    case type.GET_DASHBOARD_MY_PROGRESS_ASYNC:
      state.dashboard_my_progress.is_loading = action.is_loading;
      if (!action.is_loading) {
        state.dashboard_my_progress.data = action.data;
      }

      return { ...state };

    default:
      return state;
  }
};
export default todos;
