import React from 'react';
import { config } from '../../../../app.config';
import Axios from 'axios';
import {
    authHeaderAppJson,
    headerAppJson,
} from '../../../../SERVICES/axios-header';
import queryString from 'query-string';
import { getErrorMessageFromAxios } from '../../../../SERVICES/user-service';

// export const getVideoList = (action: any) => {
//     return Axios.get(`${config.SERVER_ENDPOINT}/video/list`, {
//         ...authHeaderAppJson(),
//         params: { ...action.params },
//     })
//         .then((resp) => resp.data)
//         .catch((err) => {
//             getErrorMessageFromAxios(err);
//             throw err;
//         });
// };

export const getVideoList = (action: any) => {
    const params = {
        ...action.params,
    };

    const url = `${config.SERVER_ENDPOINT}/video/list/${action.user_slug}`;

    var axiosConfig: any = {
        method: 'post',
        url: url,
        headers: headerAppJson(),
        data: params,
    };
    return Axios(axiosConfig)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            getErrorMessageFromAxios(err);
            throw err;
        });
};
